var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[(_vm.empresaLogo)?_c('b-img',{attrs:{"src":_vm.empresaLogo,"alt":"logo"}}):_vm._e()],1),_c('b-overlay',{attrs:{"show":_vm.loadingOverlay,"rounded":"sm"}},[_c('b-card-title',{staticClass:"mb-2"},[_vm._v(" Register ")]),(_vm.sponsor === 'null')?_c('b-alert',{staticClass:"mb-3 mt-3",attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v("Register with your sponsor link")])],1)]):_vm._e(),(_vm.sponsor && _vm.sponsor !== 'null')?_c('b-alert',{staticClass:"mb-1 mt-10",attrs:{"show":"","variant":"warning"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Sponsor ")]),_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v(_vm._s(_vm.sponsor.nome))])],1)]):_vm._e(),_c('validation-observer',{ref:"registerForm"},[(_vm.sponsor && _vm.sponsor !== 'null')?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"register-name","placeholder":"John Carter"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1255573278)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"telephone"}},[_c('validation-provider',{attrs:{"name":"Telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telephone","state":errors.length > 0 ? false:null,"name":"register-telephone","placeholder":"+55 1199999999"},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,400100582)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.emailUser),callback:function ($$v) {_vm.emailUser=$$v},expression:"emailUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,570274544)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2023063708)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Register ")])],1):_vm._e()],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v("Sign in instead")])])],1),(_vm.sponsor)?_c('b-card-text',{staticClass:"text-center mt-2"},[_c('small',[_vm._v(" This site is protected by reCAPTCHA and the Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://policies.google.com/terms"}},[_vm._v("Terms of Service")]),_vm._v(" apply")])]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }